import React from "react"

const CareersPage = () => {
  React.useEffect(() => {
    window.location.href = "https://www.linkedin.com/company/thirty-madison"
  }, [])

  return null
}

export default CareersPage
